import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { CalAngularService } from '@cvx/cal-angular';
import { MasterService } from './master.service';
import { BlobOptions } from 'buffer';

@Injectable()
export class AuthGuardService {
	cai: any;
	isAuthorized:boolean=false;
	provisioningId: any;

	constructor(private authService: CalAngularService, private router: Router,private msterService:MasterService) {
	}
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

		let data = this.authService.cvxClaimsPrincipal;
        if (data) {
		  this.provisioningId=data.provisioningId;
          }
		  this.isAuthorized=await this.msterService.ValidateUserAuthenticationAccessByProvisionId(this.provisioningId);
		  if (!this.isAuthorized) {
			alert("You are unauthorized user please contact to admin for access")
		  }
		return this.isAuthorized;
	}
	
}