import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpService } from './http.service';
import { UserDetail } from '../models/userdetails';
import { RolesDetails } from '../models/roles';
import { MenuDetail } from '../models/menu';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MasterService {


  headers: any;
  constructor(private http: HttpService, private http_: HttpClient) { 
    this.headers = { headers: { "content-type": "application/json" } }
  }


  getAllUsers(): Observable<any> {
    return this.http_.get(`${environment.baseURL}/api/UserDetail/GetUserMasterData`);
  }
  getMenuDetailsByCai(Cai:string)
  {
    return this.http.get(environment.baseURL +'/api/Master/GetMenuDataByCai?Cai=' + Cai);
  }

  GetMenuDataByProvisioningId(ProvisioningId:string)
  {
    return this.http.get(environment.baseURL +'/api/Master/GetMenuDataByProvisioningId?ProvisioningId=' + ProvisioningId);
  }

  getUserDetailsByProvisioningId(ProvisioningId:string)
  {
    return this.http.get(environment.baseURL +'/api/UserDetail/GetUserDataByProvisioningId?ProvisioningId=' + ProvisioningId);
  }
  getAllMenus(): Observable<any> {
    return this.http_.get(`${environment.baseURL}/api/Master/GetMenuMasterData`);
  }

  getAllReportStatus(): Observable<any> {
    return this.http_.get(`${environment.baseURL}/api/Master/GetReportstatusMasterData`);
  }

  
  getAllRoles(): Observable<any> {
    return this.http_.get(`${environment.baseURL}/api/Master/GetRoleMasterData`);
  }

  getAllMenuRoleMappingDetail(): Observable<any> {
    return this.http_.get(`${environment.baseURL}/api/Master/GetMenuRoleMappingData`);
  }
  getAllAllowanceMaster(): Observable<any> {
    return this.http_.get(`${environment.baseURL}/api/AllowanceMaster/GetAllowanceMasterData`);
  }

  saveUserMaster(user: UserDetail): Observable<any> {
    let userdata=JSON.stringify(user);
    return this.http_.post<any>(`${environment.baseURL}/api/UserDetail/SaveUserDetailsMaster`, userdata,{ ...this.headers, responseType: 'text' })
  }
  deleteUserDetailsMaster(UserId:string)
  {
    return this.http_.get(environment.baseURL + '/api/UserDetail/DeleteUserDetailsMaster?userid=' + UserId);
  }

  saveRoleMaster(role: RolesDetails): Observable<any> {
    let roledata=JSON.stringify(role);
    return this.http_.post<any>(`${environment.baseURL}/api/Master/saveRolesMaster`, roledata,{ ...this.headers, responseType: 'text' })
  }
  deleteRoleDetailsMaster(RoleId:string)
  {
    return this.http_.get(environment.baseURL + '/api/Master/DeleteRoleMaster?roleid=' + RoleId);
  }

  saveMenuMaster(menu: MenuDetail): Observable<any> {
    let menudata=JSON.stringify(menu);
    return this.http_.post<any>(`${environment.baseURL}/api/Master/saveMenusMaster`, menudata,{ ...this.headers, responseType: 'text' })
  }
  deleteMenuDetailsMaster(MenuId:string)
  {
    return this.http_.get(environment.baseURL + '/api/Master/DeleteMenuMaster?menuid=' + MenuId);
  }

  saveMenuRoleMappingMaster(menurolemapping: any): Observable<any> {
    let menudata=JSON.stringify(menurolemapping);
    return this.http_.post<any>(`${environment.baseURL}/api/Master/saveMenuRoleMappingMaster`, menudata,{ ...this.headers, responseType: 'text' })
  }
  deleteMenuRoleMappingMaster(MenuRoleMappingId:string)
  {
    return this.http_.get(environment.baseURL + '/api/Master/DeleteMenuRoleMappingMaster?menuroleMappingid=' + MenuRoleMappingId);
  }


  public async ValidateAdminAuthenticationAccessByProvisioningId(ProvisioningId: string):Promise<boolean> {
    let isAuthorized = false;
    const users$ = this.getUserDetailsByProvisioningId(ProvisioningId);
    let Users = await lastValueFrom(users$);
    if(Users)
    {
    if(Users.roleId=="1")  
    {
      isAuthorized=true;
    }
    }
    
    return isAuthorized;
} 


public async ValidateUserAuthenticationAccessByProvisionId(ProvisioningId: string):Promise<boolean> {
  let isAuthorized = false;
  const users$ = this.getUserDetailsByProvisioningId(ProvisioningId);
  let Users = await lastValueFrom(users$);
  if(Users)
  {
  if(Users.roleId)  
  {
    isAuthorized=true;
  }
  }
  
  return isAuthorized;
} 
}
